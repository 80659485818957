import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { LayoutWrapper } from "./Layout.style";

export default function Layout({
  blur = false,
  children,
  className = "",
}: {
  blur?: boolean;
  className?: string;
  children: ReactNode;
}) {
  return (
    <>
      <LayoutWrapper
        className={cn(
          "min-h-[100vh] max-w-[1440px] m-auto flex flex-col w-[100vw] relative overflow-hidden md:overflow-visible",
          className
        )}
      >
        {blur && (
          <>
            <div className="blur first right-[-100px] md:right-0 top-20 md:top-[-64px]"></div>
            <div className="blur last xl:block max-lg:hidden"></div>
          </>
        )}
        <Header />
        {children}
      </LayoutWrapper>
      <Footer />
    </>
  );
}
