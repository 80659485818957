import styled from "styled-components";

export const LayoutWrapper = styled.main`
  position: relative;

  .blur {
    z-index: -1;

    position: absolute;

    border-radius: 50%;
    filter: blur(150px);
    /* rotate: 45deg; */

    &.first {
      width: 360.67px;
      height: 614.79px;

      background: linear-gradient(
        0deg,
        rgba(188, 255, 46, 0.45) 0%,
        rgba(64, 255, 255, 0.45) 100%
      );
    }

    &.last {
      width: 629.13px;
      height: 782.27px;

      left: 0;
      top: 500px;

      background: linear-gradient(
        0deg,
        rgba(188, 255, 46, 0.35) 0%,
        rgba(64, 255, 255, 0.35) 100%
      );
    }
  }
`;
