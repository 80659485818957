import * as authApi from "@/api/auth.service";
import { useGetDataNotAuth } from "@/store/profileNotAuthStore";
import {
  useLogin,
  useProfile,
  useUpdateUserCVProfile,
} from "@/store/profileStore";

export default function useFetchProfile() {
  const loginProfile = useLogin();
  const getData = useGetDataNotAuth();
  const updateUserCVProfile = useUpdateUserCVProfile();
  const { accessToken } = useProfile();

  const getMe = async () => {
    window.token = accessToken;
    const profile: any = await authApi.getProfile();
    loginProfile({
      accessToken: accessToken,
      user: profile?.user,
      balance: profile?.balance,
      nft: profile?.nft,
      userCVProfile: profile?.userCVProfile,
    });
  };

  const getProfileUserNotAuth = async (address: string) => {
    try {
      const response: any = await authApi.getProfileUserNotAuth(address);
      getData({
        accessToken: "",
        user: response?.user,
        balance: response?.balance,
        nft: response?.nft,
        userCVProfile: response?.userCVProfile,
      });
    } catch (error) {
      console.log("error:", error);
    }
  };

  const updateProfile = async (data: any) => {
    try {
      const response: any = await authApi.updateInfo(data);
      updateUserCVProfile(response?.usercvprofile);
      return response;
    } catch (error) {
      console.log("error:", error);
    }
  };

  return { getMe, getProfileUserNotAuth, updateProfile };
}
