import Layout from "@/components/Layout/Layout";

export default function CareerPage() {
  return (
    <Layout>
      <div className="max-w-[1440px] px-3 mx-auto w-full md:px-20 mt-16 md:mt-20">
        <h1 className="text-xl text-center font-orbitron font-bold md:text-3xl py-10">
          Arbitrum Ambassador Program in Vietnam (coming soon)
        </h1>
        <img
          src="/images/career-bg.webp"
          alt="Career Background"
          className="w-full mx-auto h-auto"
        />
        <div>
          <div className="text-base font-normal text-white text-opacity-80 mt-10">
            As an advocate of the largest L2 protocol, ambassadors are primarily
            tasked with the growth and development of the Arbitrum ecosystem
            through education programs. This may include activities such as (but
            not limited to):
          </div>
          <ul className="list-disc ml-10 mt-3">
            <li>
              Online content creation: Producing high-quality technical and
              non-technical content, ecosystem related updates, etc.
            </li>
            <li>
              Community Engagement: Activating community participation through
              IRL meet-ups and local events
            </li>
            <li>
              Developer Onboarding: Helping developers onboard by facilitating
              developer tooling, technical workshops and hackathons
            </li>
            <li>
              Developer Onboarding: Helping developers onboard by facilitating
              developer tooling, technical workshops and hackathons
            </li>
          </ul>
        </div>
        <div className="mt-10">
          <div className="text-base font-bold">Why be a part?</div>
          <div className="text-base font-normal text-white text-opacity-80 mt-10">
            The Ambassador Program is a great opportunity to leverage Arbitrum’s
            community support to grow your social media presence and portfolio
            while helping the ecosystem thrive. By joining the program, you will
            have the benefit of:
          </div>
          <ul className="list-disc ml-10 mt-3">
            <li>
              Presenting Arbitrum in your local community as an official
              ambassador
            </li>
            <li>Working closely with Ecosystem’s biggest protocols</li>
            <li>
              Learning about scaling and Layer-2 technologies from industry
              leaders
            </li>
            <li>
              Honing digital content creation and social amplification skills
            </li>
            <li>Networking with key players in the community</li>
            <li>And becoming a part of a rapidly growing L2 ecosystem</li>
          </ul>
        </div>
        <div className="mt-10">
          <div className="text-base font-bold">Who can apply?</div>
          <div className="text-base font-normal text-white text-opacity-80 mt-10">
            If you are passionate about what Arbitrum does and is interested in
            creating an impact through social media presence and community
            engagement, this program is for you! The Arbitrum Ambassador Program
            Phase 2 applications are currently open to participants from
            Nigeria, India, and Mexico.
          </div>
        </div>
      </div>
    </Layout>
  );
}
