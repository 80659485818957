export default function TelegramIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.34952 14.4651C11.2236 11.0345 16.4742 8.77278 19.1014 7.68005C26.6025 4.56006 28.1611 4.01809 29.1771 4.00019C29.4005 3.99625 29.9001 4.05163 30.2237 4.31423C30.497 4.53596 30.5722 4.83548 30.6081 5.04571C30.6441 5.25593 30.6889 5.73483 30.6533 6.10903C30.2468 10.38 28.488 20.7447 27.5931 25.5283C27.2145 27.5524 26.469 28.2311 25.7472 28.2975C24.1787 28.4419 22.9876 27.2609 21.4684 26.265C19.0911 24.7067 17.7481 23.7366 15.4406 22.216C12.7738 20.4586 14.5025 19.4927 16.0223 17.9142C16.4201 17.5011 23.3311 11.2149 23.4649 10.6446C23.4816 10.5733 23.4971 10.3075 23.3392 10.1671C23.1813 10.0267 22.9482 10.0747 22.78 10.1129C22.5415 10.167 18.7438 12.6772 11.3868 17.6434C10.3088 18.3836 9.33245 18.7443 8.45763 18.7254C7.49322 18.7046 5.63808 18.1801 4.25897 17.7318C2.56744 17.1819 1.22305 16.8912 1.34011 15.9574C1.40109 15.471 2.07089 14.9736 3.34952 14.4651Z"
        fill="white"
      />
    </svg>
  );
}
