import { ComponentPropsWithoutRef } from "react";

export default function MenuIcon(props: ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z"
        fill="#030303"
      />
      <path
        d="M18.6704 2H16.7704C14.5904 2 13.4404 3.15 13.4404 5.33V7.23C13.4404 9.41 14.5904 10.56 16.7704 10.56H18.6704C20.8504 10.56 22.0004 9.41 22.0004 7.23V5.33C22.0004 3.15 20.8504 2 18.6704 2Z"
        fill="#030303"
      />
      <path
        d="M18.6704 13.4297H16.7704C14.5904 13.4297 13.4404 14.5797 13.4404 16.7597V18.6597C13.4404 20.8397 14.5904 21.9897 16.7704 21.9897H18.6704C20.8504 21.9897 22.0004 20.8397 22.0004 18.6597V16.7597C22.0004 14.5797 20.8504 13.4297 18.6704 13.4297Z"
        fill="#030303"
      />
      <path
        d="M7.24 13.4297H5.34C3.15 13.4297 2 14.5797 2 16.7597V18.6597C2 20.8497 3.15 21.9997 5.33 21.9997H7.23C9.41 21.9997 10.56 20.8497 10.56 18.6697V16.7697C10.57 14.5797 9.42 13.4297 7.24 13.4297Z"
        fill="#030303"
      />
    </svg>
  );
}
