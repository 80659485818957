import { TokenAmount } from "@solana/web3.js";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface IBalanceWalletState {
  native: number;
  tokens: Record<string, TokenAmount>;
}

export interface IBalanceWalletActions {
  fetchingBalance: (
    native: number,
    tokens: Record<string, TokenAmount>
  ) => void;
}

const defaultBalanceWallet: IBalanceWalletState = {
  native: 0,
  tokens: {},
};

const balanceWalletStore = create(
  immer<IBalanceWalletState & IBalanceWalletActions>((set) => ({
    ...defaultBalanceWallet,
    fetchingBalance: (native, tokens) =>
      set((draft) => {
        draft.native = native;
        draft.tokens = { ...tokens };
      }),
  }))
);

export const useNativeBalance = () =>
  balanceWalletStore((state) => state.native);

export const useTokensBalance = () =>
  balanceWalletStore((state) => state.tokens);

export const useFetchingBalance = () =>
  balanceWalletStore((state) => state.fetchingBalance);
