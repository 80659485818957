import { fetchBalances, getNativeBalance } from "@/functions/getBalanceTokens";
import { useFetchingBalance } from "@/store/balanceWalletStore";
import { useCallback, useEffect } from "react";
import useAddress from "./useAddress";

export default function useReloadBalance() {
  const { address } = useAddress();
  const setBalanceFetching = useFetchingBalance();
  const handleFetchingBalance = useCallback(async () => {
    if (!address) return;
    const native = await getNativeBalance(address);
    const tokens = await fetchBalances({ owner: address });
    setBalanceFetching(native / 1e9, tokens);
  }, [address, setBalanceFetching]);
  useEffect(() => {
    handleFetchingBalance();
    const intervalId = setInterval(() => {
      handleFetchingBalance();
    }, 8000);
    return () => clearInterval(intervalId);
  }, [address]);
  return null;
}
