import { NFTDetail } from "@/types/apis/nft";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface IUser {
  frens: number;
  id: number;
  incomeEarned: number;
  invitedBy: string;
  publicAddress: string;
  publicNFTAddress: string;
  publicTokenAddress: string;
  referralCode: string;
}

export interface IBalanceProfile {
  Address: string;
  balance: number;
  id: number;
  interest: number;
  itemId: number;
  name: string;
  status: number;
  symbol: string;
}

export interface ProfileState {
  user: IUser;
  balance: IBalanceProfile[];
  accessToken: string;
  nft: NFTDetail;
  userCVProfile: UserCVProfile;
}

export interface UserCVProfile {
  aboutMe: string;
  email: string;
  facebook: string;
  fullName: string;
  id: number;
  languages: string;
  linkedin: string;
  phone: string;
  profileImage: string;
  schoolName: string;
  skillAdvance: string;
  skillNormal: string;
  twitter: string;
  userId: number;
}

export interface UserActions {
  login: (user: ProfileState) => void;
  logout: () => void;
  handleUpdateUserCVProfile: (user: UserCVProfile) => void;
}

export type TUserStore = ProfileState & UserActions;

const defaultUser = {
  id: -1,
  publicAddress: "",
  publicTokenAddress: "",
  publicNFTAddress: "",
  referralCode: "",
  invitedBy: "",
  incomeEarned: 0,
  frens: 0,
};

const defaultUserCVProfile = {
  aboutMe: "",
  email: "",
  facebook: "",
  fullName: "",
  id: -1,
  languages: "",
  linkedin: "",
  phone: "",
  profileImage: "",
  schoolName: "",
  skillAdvance: "",
  skillNormal: "",
  twitter: "",
  userId: -1,
};

const defaultNft = {
  id: -1,
  userId: 0,
  class: "",
  level: 0,
  image: "",
  Score: 0,
  Status: 0,
};
const logoutUser = (draft: ProfileState) => {
  draft.user = { ...defaultUser };
  draft.balance = [];
  draft.accessToken = "";
  draft.nft = { ...defaultNft };
  draft.userCVProfile = { ...defaultUserCVProfile };
};

const handleLogin = (draft: TUserStore, data: ProfileState) => {
  draft.user = data.user;
  draft.accessToken = data.accessToken;
  draft.balance = data.balance;
  draft.nft = data.nft;
  draft.userCVProfile = data.userCVProfile;
};

const handleUpdateUserCVProfile = (draft: TUserStore, data: UserCVProfile) => {
  draft.userCVProfile = data;
};

const profileStore = create(
  persist(
    immer<TUserStore>((set) => ({
      user: { ...defaultUser },
      balance: [],
      accessToken: "",
      nft: defaultNft,
      userCVProfile: defaultUserCVProfile,
      login: (data: ProfileState) => set((draft) => handleLogin(draft, data)),
      logout: () => set((draft) => logoutUser(draft)),
      handleUpdateUserCVProfile: (data: UserCVProfile) =>
        set((draft) => handleUpdateUserCVProfile(draft, data)),
    })),
    {
      name: "profile-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useLogin = () => profileStore((state) => state.login);
export const useLogout = () => profileStore((state) => state.logout);
export const useUpdateUserCVProfile = () =>
  profileStore((state) => state.handleUpdateUserCVProfile);
export const useProfile = () =>
  profileStore((state) => ({
    user: state.user,
    balance: state.balance,
    accessToken: state.accessToken,
    nft: state.nft,
    userCVProfile: state.userCVProfile,
  }));
