import { ComponentPropsWithoutRef } from "react";

export default function XIcon(props: ComponentPropsWithoutRef<"svg">) {
  const { fill = "#030303", ...rest } = props;
  return (
    <svg
      {...rest}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0227367 20C0.407746 19.5559 0.789723 19.1036 1.17928 18.6602C3.34382 16.1982 5.50962 13.7371 7.67668 11.2771C7.72064 11.2275 7.76081 11.1757 7.81007 11.1157L0 0.00111785H0.213725C2.07157 0.00111785 3.92891 0.00111785 5.78574 0.00111785C5.84344 -0.00379229 5.90137 0.00754132 5.95267 0.0337778C6.00398 0.0600143 6.04652 0.100056 6.07526 0.149164C7.71028 2.48337 9.34809 4.8151 10.9887 7.14437C11.0296 7.20285 11.0728 7.25984 11.1319 7.34053C11.2964 7.15621 11.4525 6.98374 11.6064 6.80978C13.5642 4.58711 15.5211 2.36271 17.477 0.136581C17.5122 0.0914168 17.5583 0.0554311 17.6112 0.0318046C17.6641 0.00817816 17.7221 -0.00236135 17.7801 0.00111785C18.2917 0.00777994 18.8048 0.00111785 19.3717 0.00111785L11.9216 8.46939C11.961 8.52712 11.9974 8.58486 12.0399 8.64186C14.6546 12.3613 17.2691 16.0807 19.8833 19.8001C19.9272 19.8631 19.9591 19.9334 20 20H14.0468C14.0348 19.9771 14.0217 19.9549 14.0074 19.9334C12.2349 17.4102 10.4619 14.8867 8.68847 12.363C8.65901 12.3262 8.62765 12.2909 8.59449 12.2572C8.54295 12.3119 8.49748 12.3593 8.45428 12.4052C7.29925 13.7164 6.14498 15.0279 4.99147 16.3395C3.92032 17.558 2.85018 18.7769 1.78105 19.9963L0.0227367 20ZM2.41995 1.30319C2.47679 1.38906 2.50711 1.43791 2.54121 1.48529C6.62422 7.19495 10.7062 12.9046 14.7872 18.6143C14.8146 18.6611 14.8549 18.6993 14.9036 18.7246C14.9523 18.7499 15.0073 18.7612 15.0623 18.7571C15.8346 18.7512 16.6069 18.7571 17.3792 18.7571C17.4361 18.7571 17.4929 18.752 17.577 18.7475C17.5217 18.6631 17.4815 18.5995 17.4376 18.5366C13.4652 12.9814 9.49689 7.42418 5.53261 1.86503C5.22945 1.43717 4.92629 1.24989 4.39577 1.29134C3.75535 1.34168 3.10736 1.30319 2.41995 1.30319Z"
        fill={fill}
      />
    </svg>
  );
}
