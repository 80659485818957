import { ENVS } from "@/configs/Configs.env";
import { ConnectionSol } from "@/utils/constants";
import { Connection, PublicKey, TokenAmount } from "@solana/web3.js";

export const fetchBalances = async ({ owner }: { owner: string }) => {
  const connection = new Connection(ENVS.REACT_APP_SOL_RPC);

  const ownerAddress = owner;
  const ownerPublicKey = new PublicKey(ownerAddress);

  const balances: Record<string, TokenAmount> = {};
  try {
    const tokenPublicKey = new PublicKey(ENVS.REACT_APP_USDT_TOKEN);
    const balance = await connection.getParsedTokenAccountsByOwner(
      ownerPublicKey,
      {
        mint: tokenPublicKey,
      }
    );
    balance.value.forEach(({ account }) => {
      if (
        account.data.parsed.info.mint.toLowerCase() ===
        ENVS.REACT_APP_USDT_TOKEN.toLowerCase()
      ) {
        balances[ENVS.REACT_APP_USDT_TOKEN] =
          account.data.parsed.info.tokenAmount;
      }
    });
  } catch (error) {}
  try {
    const tokenPublicKey = new PublicKey(ENVS.REACT_APP_USDC_TOKEN);
    const balance = await connection.getParsedTokenAccountsByOwner(
      ownerPublicKey,
      {
        mint: tokenPublicKey,
      }
    );
    balance.value.forEach(({ account }) => {
      if (
        account.data.parsed.info.mint.toLowerCase() ===
        ENVS.REACT_APP_USDC_TOKEN.toLowerCase()
      ) {
        balances[ENVS.REACT_APP_USDC_TOKEN] =
          account.data.parsed.info.tokenAmount;
      }
    });
  } catch (error) {}

  return balances;
};

export const getBalanceToken = async (
  address: string,
  tokenAddress: string
) => {
  const ownerPublicKey = new PublicKey(address);
  let balance = 0;
  try {
    const pubkey = new PublicKey(tokenAddress);
    const parsedTokens = await ConnectionSol.getParsedTokenAccountsByOwner(
      ownerPublicKey,
      {
        mint: pubkey,
      }
    );
    parsedTokens.value.forEach(({ account }) => {
      if (
        account.data.parsed.info.mint.toLowerCase() ===
        tokenAddress.toLowerCase()
      ) {
        balance = account.data.parsed.info.tokenAmount.amount;
      }
    });
  } catch (error) {
    // console.log("🚀 ~ error:", error);
  }
  return balance;
};

export const getNativeBalance = async (address: string) => {
  try {
    const ownerPublicKey = new PublicKey(address);
    return ConnectionSol.getBalance(ownerPublicKey);
  } catch (error) {
    console.log("🚀 ~ getNativeBalance ~ error:", error);
  }
  return 0;
};
