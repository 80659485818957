import FacebookIcon from "@/assets/icons/FacebookIcon";
import TelegramIcon from "@/assets/icons/TelegramIcon";
import XIcon from "@/assets/icons/XIcon";
import LogoImg from "@/assets/images/logo.png";

export default function Footer() {
  return (
    <div className="pt-6 mt-10 border-t border-borderColor rounded-t-[40px]">
      <div className="md:p-6 md:px-10 p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full px-3 md:px-20 mx-auto">
          <div className="col-span-1">
            <div className="flex flex-col justify-between min-h-[80px] h-full">
              <div className="text-xl font-semibold">
                <img src={LogoImg} className="h-[62px]" />
              </div>
              <div className="flex gap-6 items-center">
                <FacebookIcon />
                <XIcon fill="white" />
                <TelegramIcon />
              </div>
            </div>
          </div>
          <div className="col-span-1 mt-5 md:mt-0">
            <div className="grid grid-cols-3">
              <div className="flex flex-col gap-4">
                <div className="text-sm font-semibold font-zen-dots">Help</div>
                <div className="text-sm font-normal text-neutral-400">
                  Support
                </div>
                <div className="text-sm font-normal text-neutral-400">
                  Beginner's Guide
                </div>
                <div className="text-sm font-normal text-neutral-400">FAQs</div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="text-sm font-semibold font-zen-dots">
                  Company
                </div>
                <div className="text-sm font-normal text-neutral-400">
                  Branding
                </div>
                <div className="text-sm font-normal text-neutral-400">Blog</div>
                {/* <div className="text-sm font-normal text-neutral-400">
                  Topic
                </div> */}
              </div>
              <div className="flex flex-col gap-4">
                <div className="text-sm font-semibold font-zen-dots">Legal</div>
                <div className="text-sm font-normal text-neutral-400">
                  Copyright Policy
                </div>
                <div className="text-sm font-normal text-neutral-400">
                  Terms of Service
                </div>
                <div className="text-sm font-normal text-neutral-400">
                  Privacy Policy
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10 mt-10 flex items-center justify-center w-full bg-linear-orange text-xs md:text-sm font-medium text-[#030303]">
        2024 Chaincohort Inc. All Rights Reserved
      </div>
    </div>
  );
}
